.analytics-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 36px 36px 0;

  &__notes-section {
    padding: 15px 5px;
    color: #7e551c;
    background-color: #fef6e9;
  }

  &__title-section {
    &-row {
      display: flex;
      align-items: center;
    }

    &-text {
      font-size: 35px;
      line-height: 35px;
    }

    &-question-icon {
      width: 16px;
      height: 16px;
      margin-left: 16.25px;
      fill: #74777a;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
