.checkbox-for-updates {
  &-row {
    display: flex;
    align-items: center;
  }

  &-update-settings-tooltip {
    max-width: 450px;
  }
}
