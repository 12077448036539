.app-dashboard-left-sidebar-nav-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 208px;
  height: 66px;
  padding: 0;
  color: #9fa2a5;
  text-decoration: none;
  background: var(--color-gray-1);
  border: 0;
  border-radius: 2px;

  &-icon-box,
  &-icon {
    width: 28px;
    max-width: 28px;
    height: 24px;
    max-height: 24px;
  }

  &-icon-box {
    margin-left: 20px;
  }

  & svg {
    fill: currentcolor;
  }

  &-dropdown {
    width: 28px;
    height: 24px;
    margin-right: 5px;
    margin-left: auto;
  }

  &-label {
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  &--selected {
    align-items: center;
    color: white;
    background: #2a2d30;

    & svg {
      background: #2a2d30;
      fill: white;
    }

    &-icon-box,
    &-icon {
      width: 28px;
      max-width: 28px;
      height: 24px;
      max-height: 24px;
    }

    &-icon-box {
      margin-left: 20px;
    }

    &-dropdown {
      width: 28px;
      height: 24px;
      margin-right: 5px;
      margin-left: auto;
    }

    &-label {
      margin-left: 20px;
      color: white;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      background: #2a2d30;
    }
  }

  &--sub-button {
    margin: 0;
    background: #2a2d30;

    &-icon-box,
    &-icon {
      width: 28px;
      max-width: 28px;
      height: 24px;
      max-height: 24px;
    }

    &-icon-box {
      margin-left: 20px;
    }

    &-icon {
      margin-left: 6%;
      background: #2a2d30;
    }

    &-label {
      margin-left: 20px;
      padding: 0;
      overflow: none;
      font-weight: 500;
      font-size: 16px;
      background: #2a2d30;
    }
  }

  &:hover {
    color: var(--color-green-3);
    cursor: pointer;

    & svg {
      fill: var(--color-green-3);
    }
  }
}

@media only screen and (width <= 1249px) {
  .app-dashboard-left-sidebar-nav-btn {
    width: 66px;
    height: 66px;

    &-icon-box,
    &-icon {
      width: 28px;
      max-width: 28px;
      height: 24px;
      max-height: 24px;
    }

    &-label,
    &-dropdown {
      display: none;
      color: currentcolor;
    }

    &--selected {
      &-icon-box {
        width: 28px;
        height: 24px;
      }

      &-icon-box,
      &-icon {
        width: 28px;
        max-width: 28px;
        height: 24px;
        max-height: 24px;
      }

      & svg {
        fill: white;
      }

      &-label,
      &-dropdown {
        display: none;
      }
    }

    &--sub-button {
      justify-content: start;
      width: 208px;
      height: 66px;
      margin-top: 0;

      &-icon-box,
      &-icon {
        width: 28px;
        max-width: 28px;
        height: 24px;
        max-height: 24px;
      }

      &-label {
        margin: 0;
        margin-left: 20px;
        color: currentcolor;
      }
    }

    &:hover {
      color: var(--color-green-3);
      cursor: pointer;

      & svg {
        fill: var(--color-green-3);
      }
    }
  }
}
