.quote-page {
  height: 100%;
  width: 100%;

  &__main-content-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 25px;
  }

  &__header {
    padding: 4rem;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    background-color: #efeff1;
    border-bottom: 1px solid #d9dbde;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 20px;
    padding: 10px 20px;
  }

  &__separator {
    border-right: 1px solid black;
  }
}
