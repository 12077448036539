.app-dashboard-left-sidebar {
  display: inline-flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  background: var(--color-gray-1);

  &__header {
    &-logo-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 120px;
      padding: 0;

      & svg {
        fill: #f6f6f7;
      }

      &-icon {
        width: 30px;
        height: 34px;
      }

      &-name-icon {
        width: 180px;
        height: auto;
        animation: fadeIn 1.5s;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-logout-btn {
      color: var(--color-gray-4);

      & svg {
        width: 22px;
        height: 17px;
      }
    }
  }

  &__tooltip {
    display: none;
  }
}

@media only screen and (width <= 1249px) {
  .app-dashboard-left-sidebar {
    width: 98px;
    min-width: 98px;

    &__header {
      &-logo-btn {
        &-icon {
          animation: fadeIn 1.5s;
        }
      }
    }

    &__tooltip {
      display: initial;
    }

    &__dropdown {
      position: absolute;
      z-index: 1;
    }

    &:hover {
      &__sub-button {
        position: relative;
        display: initial;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
