.r-b-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;

  &__inner {
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    border: solid 1px var(--color-gray-4);
    cursor: inherit;

    &--checked {
      background: var(--color-blue-3);
      border-color: var(--color-blue-3);
    }

    &-type--circle {
      border-radius: 50%;
    }

    &--right {
      display: flex;
      order: 1;
      margin-left: 8px;
    }
  }

  &__label {
    margin-left: 8px;
    color: var(--color-gray-6);
    font-weight: bold;
    font-size: 12px;

    &--left {
      display: flex;
      order: 0;
      margin-left: 0;
    }
  }
}
