.self-service-page {
  padding: 0 20px;
  width: 100%;

  &__title {
    margin-top: 10px;
    margin-bottom: 60px;
    font-size: 40px;
    font-weight: 700;
  }

  &__component-container {
    width: 100%;
  }
}
