.app-tooltip {
  z-index: 2;

  &-icon {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    fill: #74777a;

    &:hover {
      cursor: pointer;
    }
  }

  padding: 8px 12px;
  background-color: var(--color-gray-6) !important;
  border-radius: 2px;
  transition: none !important;

  &.place-bottom {
    margin-top: 7px;

    &::after {
      border-bottom-color: var(--color-gray-6) !important;
    }
  }

  &.place-right {
    margin-left: 7px;

    &::after {
      border-right-color: var(--color-gray-6) !important;
    }
  }

  &.place-left {
    margin-right: 7px;

    &::after {
      border-left-color: var(--color-gray-6) !important;
    }
  }

  &.place-top {
    margin-bottom: 7px;

    &::after {
      border-top-color: var(--color-gray-6) !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
