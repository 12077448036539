.date-picker-input {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 10px;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &--icon {
    height: 16px;
    margin-right: 10px;
    fill: var(--color-blue-3);
  }

  &--input {
    width: 90px;
  }

  &--not-selected {
    color: var(--color-gray-4);
  }

  &--divider {
    height: 20px;
    width: 1px;
    background-color: var(--color-gray-3);
    margin: 0 10px;
  }
}
