.tools-page {
  flex: 1;
  padding: 4rem;

  .px-4 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .padding-x-20 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .p-10 {
    padding: 2.5rem;
  }

  .h-6 {
    height: 1.5rem;
  }

  .w-4 {
    width: 1rem;
  }

  .width-104 {
    width: 104px;
  }

  .w-32 {
    width: 8rem;
  }

  .width-600 {
    width: 600px;
  }

  .h-10 {
    height: 40px;
  }

  .height-200 {
    height: 200px;
  }

  .table-border {
    border: 1px solid #adb5bd;
    border-collapse: collapse;
  }

  .textarea-border {
    /* TODO: Add color variables, like `--color-grayscale-5`, from hub */
    border: 1px solid #adb5bd;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .bg-white {
    background-color: white;
  }

  .bg-gray {
    background-color: #e9ecef;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .rounded-lg {
    border-radius: 0.5rem;
  }

  .text-lg {
    font-size: 18px;
  }

  .bg-blue {
    background-color: #276de2;
  }

  .text-white {
    color: white;
  }

  .focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .hover\:outline-black:hover {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-medium {
    font-weight: 500;
  }
}
