.r-b-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  padding: 5px;
  color: #111314;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  background-color: #efeff1;
  border: 0;
  border-radius: 2px;
  cursor: pointer !important;

  &:focus {
    outline: none;
  }

  &:active {
    box-shadow: none;

    & svg {
      height: 17px;
    }
  }

  &--primary {
    color: var(--color-gray-1);
    background-color: white;
  }

  &--secondary {
    color: white;
    background-color: var(--color-gray-1);
  }

  &--disabled {
    color: var(--color-gray-4);
    background-color: #f6f6f7;
    box-shadow: none;
    pointer-events: none;
  }

  /* Layout */
  &-layout--rtl {
    flex-direction: row-reverse;
  }

  &--circle {
    border-radius: 50%;
  }

  &--floating {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  /* Variants */
  &-type {
    /* Outlined */
    &-outlined {
      background: none;
      border: solid 1px currentcolor;
      box-shadow: none;

      &--primary {
        color: var(--color-gray-1);
      }

      &--secondary {
        color: white;
      }

      &--disabled {
        color: var(--color-gray-1);

        /* color: var(--color-gray-5); */

        border-color: var(--color-gray-1);
      }
    }

    /* Text */
    &-text {
      background: none;
      box-shadow: none;

      &--primary {
        color: var(--color-gray-1);
      }

      &--secondary {
        color: white;
      }

      &--disabled {
        color: var(--color-gray-1);
        background: none;
      }
    }
  }

  /* icon spacer */
  & > div {
    width: 8px;
    height: 8px;
  }

  /*  SVG icon */
  & > svg {
    width: 18px;
    height: 18px;
    fill: currentcolor;
  }
}
