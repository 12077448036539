.settings-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    padding: 4rem;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    background-color: #efeff1;
    border-bottom: 1px solid #d9dbde;
  }

  &__main-content-container {
    width: 100%;
    max-width: 800px;
    padding: 25px 50px;
  }

  &__h-6 {
    height: 1px;
    margin: 10px 0 25px;
    background-color: #9fa2a5;;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
  }

  &__message-error,
  &__message-success {
    max-width: 500px;
  }

  &__message-success {
    svg {
      fill: green;
    }

    background-color: lightgreen;
  }

  &__button-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
  }

  &__button-container {
    display: flex;
  }

  &__save-button {
    padding: 0 1rem;
  }

  &__cancel-button {
    margin-right: 10px;
    padding: 0 1rem;
    border: 1px solid #d9dbde;
  }

  &__selection-container {
    display: flex;
    margin-bottom: 24px;
    font-weight: 500;

    &-active {
      color: #276de2;
      border-bottom: 2px solid #276de2;
    }

    &-margin {
      margin-left: 28px;
    }

    > div {
      cursor: pointer;
    }
  }
}
