.create-order-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__header {
    padding: 4rem;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    background-color: #efeff1;
    border-bottom: 1px solid #d9dbde;
  }

  &__form-container {
    padding: 5px 20px;
  }
}
