.error-message {
  background-color: lightgray;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 5px;

  &__text {
    color: red;
    display: block;
  }
}
