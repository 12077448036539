.create-order-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 600px;

  &__input-container {
    padding: 10px 20px;
  }

  &__button-container {
    margin-top: 20px;
    display: flex;

    &>button:last-child {
      margin-left: 10px;
    }
  }

  &__button {
    padding: 0 1rem;
  }

  &__newCnee {
    text-align: end;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 12px;
  }
}