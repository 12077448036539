@import-normalize;

html,
body,
#app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.005em;
  -webkit-font-smoothing: antialiased;
}
