.date-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  &__clear-button {
    display: flex;
    cursor: pointer;
    background-color: var(--color-gray-4);
    padding: 2px;
    border-radius: 100%;

    > svg {
      height: 16px;
      width: 16px;
      fill: white;
    }
  }

  &__label {
    font-weight: 600;
    width: 125px;
    flex: 1;
  }

  .react-datepicker-wrapper {
    display: flex;
    height: 100%;
    padding: 5px;
    border: 1px solid var(--color-gray-3);
    border-radius: 2px;
    margin-left: 10px;
    flex: 3;
  }

  .react-datepicker-wrapper > div > button.react-datepicker__close-icon::after {
    color: white;
    background-color: var(--color-gray-4);
  }
}
