.r-b-select-dropdown-pill {
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  background: var(--color-blue-3);

  & svg {
    width: 14px;
    height: 14px;
    fill: white;
  }
}
