.select-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  &__label {
    font-weight: 600;
    width: 125px;
    flex: 1;
  }

  &__input {
    margin-left: 10px;
    min-width: 175px;
    flex: 3;
    white-space: pre-wrap; /* line wrap for \n */
  }
}