.prism-page {
  padding: 30px;
  width: 100%;

  button {
    padding: 10px;
    margin-left: 10px;
  }

  &__download-btn {
    &>svg {
      width: 21px;
      height: 21px;
    }
  }

  .prism-container-entry {
    display: flex;
    flex-direction: row;

    button {
      padding: 10px;
      margin-left: 10px;
    }

    textarea {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: hsl(0deg 0% 80%);
      background-color: hsl(0deg 0% 100%);
      padding-left: 10px;
      height: 150px;
      width: 500px;
    }

    .actions {
      display: flex;
      padding: 20px 0;
    }

    .invalid-entries {
      color: red;
      padding: 30px;
    }
  }

  .actions {
    display: flex;
    padding: 20px 0;

    textarea {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: hsl(0deg 0% 80%);
      background-color: hsl(0deg 0% 100%);
      padding-left: 10px;
      height: 200px;
      width: 500px;
      ;
    }
  }

  .search {
    display: flex;
    padding: 20px 0;
    gap: 20px;

    .spacer {
      flex: 1;
    }

    input {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: hsl(0deg 0% 80%);
      background-color: hsl(0deg 0% 100%);
      padding-left: 10px;
      min-height: 38px;
      width: 200px;
    }

    .filterSelector {
      width: 300px;
    }
  }

  table {
    border-collapse: collapse
  }

  td {
    padding: 5px 10px;
    white-space: pre;
    vertical-align: baseline;
    text-align: left;
  }

  th {
    padding: 0 10px;
  }

  tr:nth-child(even) {
    background-color: #eaeaea;
  }
}