.r-b-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  background-color: var(--color-blue-3);
  border-radius: 30px;

  & svg {
    height: 14px;
  }

  &__label {
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      height: 14px;
      margin-left: 8px;
      cursor: pointer;
      fill: currentcolor;
    }
  }

  &__loader {
    margin-right: 8px;
  }
}
