.quote {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 650px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 20px;
    padding: 10px 20px;
  }

  &__newCnee {
    text-align: end;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 12px;
  }
}