.app-system-toast {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 50px;
  padding: 9px 0 11px 14px;
  color: white;
  background: var(--color-gray-1);

  & > p {
    display: flex;
    flex: 1;
    margin: 0;
    margin-right: 14px;
    line-height: 16px;
  }

  /* icon */
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    fill: currentcolor;

    /* INFO */
    &--info {
      color: var(--color-blue-3);
    }

    /* ERROR */
    &--error {
      color: red;
    }

    /* WARN */
    &--warn {
      color: yellow;
    }

    /* SUCCESS */
    &--success {
      color: #41a741;
    }
  }

  /* actions */
  &__actions {
    display: flex;
    align-items: center;
    margin-right: 14px;
  }
}
