.app-global-modal {
  z-index: var(--elevation-3);
  background-color: rgb(17 18 18 / 90%);

  &__close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 11111;
    width: 16px;
    height: 16px;
    fill: white;
  }
}
