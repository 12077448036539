.day-window-switch {
  &-wrapper {
    align-items: right;
    margin-bottom: 5px;
  }

  &__day-name {
    font-size: 14px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-left: 10px;
  }

  &__time-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__time-selector-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 10px 0;

    > div {
      font-size: 12px;
      margin-bottom: 2px;
      color: #737980;
    }

    > input {
    }
  }

  &__time-input {
    width: 100px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 5px 8px;
    border: 1px solid #d9dbde;
    border-radius: 2px;

    &-invalid {
      width: 100px;
      margin-top: 3px;
      margin-bottom: 3px;
      padding: 5px 8px;
      border: 1px solid #cb6565;
      background-color: #f9d6d6;
      border-radius: 2px;
    }
  }
}
