.quote-list {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  max-width: 650px;

  &__title {
    &-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &-main {
      font-size: 24px;
      font-weight: 700;
    }

    &-info {
      font-size: 16px;
      font-weight: 400;
      color: #7A7A7A;
      margin-left: 10px;
    }
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1000px;
    overflow-y: auto
  }
}
