.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  &__label {
    font-weight: 600;
    width: 125px;
    flex: 1;
  }

  &__input {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: hsl(0deg 0% 80%);
    background-color: hsl(0deg 0% 100%);
    margin-left: 14px;
    padding-left: 10px;
    line-height: 1.5;
    min-height: 38px;
    flex: 3;

    &-error {
      border-color: red;
      color: red;
    }
  }
}