.ErrorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 600px;
    height: 429px;
  }

  &__details {
    width: 100%;
    white-space: pre-wrap;
  }

  &__content {
    min-width: 800px;
  }
}
