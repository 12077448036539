.app-dashboard-layout {
  display: flex;
  height: 100%;

  &__body {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    &-content {
      display: flex;
      height: 100%;
      overflow: auto;

      /* Add iOS momentum scrolling. */
      --webkit-overflow-scrolling: 'touch';
    }
  }
}
