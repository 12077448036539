.app-system-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  color: var(--color-gray-5);
  background: var(--color-gold-2);

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 100%;
    background: var(--color-gray-5);
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;
      fill: var(--color-gold-2);
    }
  }

  &__body {
    padding: 0 8px;

    &-name {
      font-weight: 500;
    }

    & > * {
      margin-left: 4px;
    }
  }
}
