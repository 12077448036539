.message {
  &__text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 16px;
    background-color: var(--color-red-1);
    border-radius: 2px;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--color-red-4);
    }

    pre {
      margin: 0 0 0 12px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
