.r-b-select-dropdown-multi-option {
  display: flex;
  align-items: center;
  padding: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;

  &--selected {
    background: var(--color-gray-2);
  }

  &:hover {
    background: var(--color-gray-2);
  }

  &__checkbox {
    height: 100%;

    .r-b-checkbox__label {
      margin-left: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.01em;
    }
  }
}
