.consignee-creation-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    padding: 4rem;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    background-color: #efeff1;
    border-bottom: 1px solid #d9dbde;
  }

  &__main-content-container {
    width: 100%;
    max-width: 800px;
    padding: 25px 50px;
  }

  &__h-6 {
    height: 1px;
    margin: 10px 0 25px;
    background-color: #9fa2a5;
  }

  &__spacer {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: #d9dbde;
  }

  &__button-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
  }

  &__button-container {
    display: flex;
  }

  &__create-button {
    padding: 0 1rem;
  }

  &__clear-button {
    margin-right: 10px;
    padding: 0 1rem;
    border: 1px solid #d9dbde;
  }

  &__title {
    font-weight: 500;
    size: 16px;
  }

  &__sub-title {
    margin-bottom: 10px;
    color: #5d5f62;
    size: 16px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
  }

  &__input-row {
    display: flex;
    align-items: center;
    width: 100%;

    > input {
      width: 500px;
      margin-top: 3px;
      margin-bottom: 3px;
      padding: 14px 16px;
      border: 1px solid #d9dbde;
      border-radius: 2px;
    }
  }

  &__labeled_switch_wrapper {
    padding-bottom: 5px;

    &_important {
      padding-bottom: 25px;
    }
  }

  &__day-window-container {
    width: 850px;

    > div {
      margin-bottom: 0.5rem;
    }
  }

  &__sop-time {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
}
