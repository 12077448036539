.labeled-switch {
  &__wrapper {
    display: flex;
    justify-content: left;
  }

  &__switch {
    padding-right: 8px;
  }

  &__label {
    font-size: 14px;
  }
}
