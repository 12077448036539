.email-addresses {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 500;
    size: 16px;
  }

  &__sub-title {
    margin-bottom: 20px;
    color: #5d5f62;
    size: 16px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
  }

  &__input-row {
    display: flex;
    align-items: center;
    width: 100%;

    > input {
      width: 500px;
      margin-top: 3px;
      margin-bottom: 3px;
      padding: 14px 16px;
      border: 1px solid #d9dbde;
      border-radius: 2px;
    }
  }

  &__icon {
    width: 22px;
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
    fill: #e23564;
  }

  &__add-new-email {
    align-self: flex-end;
    margin-top: 4px;
    color: #276de2;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;

    &-offset {
      margin-right: 37px;
    }
  }
}
