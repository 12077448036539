.r-b-modal {
  position: absolute;
  inset: 0;
  z-index: 9999;
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
  background: #000a;
  transform: scale3d(1, 0, 1);

  /* Add iOS momentum scrolling. */
  --webkit-overflow-scrolling: 'touch';

  &--show {
    transform: scale3d(1, 1, 1);
  }

  &--fullscreen {
    position: fixed;
    inset: 0;
  }

  &__button {
    position: absolute;
    top: 5%;
    right: 5%;
    padding: 7px 15px;
    color: #fff;
    font-size: 2rem;
    border: solid thin #fff;
    cursor: pointer;
  }
}
