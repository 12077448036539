.list-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  box-shadow: rgb(100 100 111 / 20%) 0 3px 3px 0;
  padding: 20px;
  width: 100%;
  max-width: 650px;
  min-width: 265px;
  border-radius: 2px;

  /* background-color: #ececef; */

  &.expired {
    background-color: #fce3e6;
    color: #f44336;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  &__button {
    user-select: none;
  }

  &__column {
    flex: 1
  }

  &__row {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: 600;
  }

  >h3 {
    margin: 0;
    margin-bottom: 4px;
  }

  &__overview {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0;
    margin-bottom: 10px;
  }

  &__details {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0;
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
    gap: 10px;
  }

  hr {
    border: none;
    color: #d3d1d1;
    background-color: #d3d1d1;
    height: 1px;
  }

}
