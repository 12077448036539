.daily-digest-settings {
  &__section {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-vertical {
      flex-direction: column;
    }

    &-dropdown {
      height: 50px;
      margin-top: 60px;
    }

    &-first {
      margin-bottom: 24px;
    }
  }

  &__label-container {
    display: flex;

    > span {
      margin-right: 10px;
      color: #000;
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__spacer {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: #d9dbde;
  }

  &__title {
    margin-bottom: 6px;
    font-weight: 500;
    size: 16px;
  }

  &__sub-title-container {
    color: #5d5f62;
    font-size: 16px;

    > div:last-child {
      margin-top: 6px;
      font-style: italic;
    }
  }

  &__digest-times-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  &__digest-times-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__digest-times-row-text {
    width: 358px;
  }

  &__digest-times-row-dropdown-container {
    display: flex;
    align-items: center;

    > div {
      padding: 0 10px;
      border: 1px solid #d9dbde;
    }
  }

  &__add-new-button {
    align-self: flex-end;
    margin-top: 4px;
    margin-right: 200px;
    color: #276de2;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__icon {
    width: 22px;
    height: 20px;
    margin-left: 15px;
    cursor: pointer;
    fill: #e23564;
  }
}
