.app-dashboard-header {
  display: flex;
  min-height: 61px;
  padding: 0 32px;
  overflow: hidden;
  border-bottom: 1px solid var(--color-gray-3);

  &__body {
    flex-grow: 1;
  }

  &__right-nav {
    display: inline-flex;
    align-items: center;

    &-btn {
      width: 140px;
      height: 40px;
      border-color: var(--color-gray-3);
    }

    & > * {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
